import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
const Login = lazy(() => import("../pages/Loging"));
const OtpVerify = lazy(() => import("../pages/Loging/OtpVerify"));

const PrivateRoutes = () => {
  const allPrivateRoutes = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/login/otp-verify",
      element: <OtpVerify />,
    },
    {
      path: "/",
      element: <Navigate replace to="/login" />,
    },
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);
  return allPrivateRoutes;
};

export default PrivateRoutes;
