import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  accessToken: string | null;
  emailFromOtp: string;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  emailFromOtp: "",
  refreshToken: null,
};

const adminLoginSlice = createSlice({
  name: "loginTokken",
  initialState,
  reducers: {
    logoutAction: (): AuthState => initialState,
    accessAdminTokken(state, action) {
      state.accessToken = action.payload;
    },
    getEmailforOtp(state, action) {
      if (action.payload) {
        state.emailFromOtp = action.payload;
      }
    },
    accessRefreshToken(state, action) {
      if (action.payload) {
        state.refreshToken = action.payload;
      }
    },
  },
});
export const {
  accessAdminTokken,
  logoutAction,
  getEmailforOtp,
  accessRefreshToken,
} = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
