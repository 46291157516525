import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
const Home = lazy(() => import("../pages/Home"));
const Layout = lazy(() => import("../Layout"));
const Community = lazy(() => import("../pages/Community"));
const Race = lazy(() => import("../pages/Race"));
const Payment = lazy(() => import("../pages/Payment"));
const User = lazy(() => import("../pages/User"));
const RaceDetails = lazy(() => import("../pages/Race/RaceDetails"));
const RegistrationReports = lazy(
  () => import("../pages/Reports/RegistrationReports")
);
const DonationReports = lazy(() => import("../pages/Reports/DonationReports"));
const VolunteerReports = lazy(
  () => import("../pages/Reports/VolunteerReports")
);
const TshirtsReports = lazy(() => import("../pages/Reports/TshirtsReports"));
const FundRaisingReports = lazy(() => import("../pages/Reports/FundRaisingReports"));
const AddOnReports = lazy(() => import("../pages/Reports/AddOnReports"));
const TeamDetailsTabs = lazy(
  () => import("../pages/Race/RaceDetails/TeamDetails/TeamDetailsTabs")
);

const PrivateRoutes = () => {
  const allPrivateRoutes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Navigate replace to="home" />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "user",
          element: <User />,
        },
        {
          path: "community",
          element: <Community />,
        },
        {
          path: "race",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Race />,
            },
            {
              path: ":race_id",
              element: <Outlet />,

              children: [
                {
                  path: "",
                  element: <RaceDetails />,
                },
                {
                  path: "team-details/:slug",
                  element: <TeamDetailsTabs />,
                },
              ],
            },
          ],
        },

        {
          path: "reports",
          element: <Outlet />,
          children: [
            {
              path: "/reports/registration-reports",
              element: <RegistrationReports />,
            },
            {
              path: "/reports/donation-reports",
              element: <DonationReports />,
            },
            {
              path: "/reports/volunteer-reports",
              element: <VolunteerReports />,
            },
            {
              path: "/reports/t-shirt-reports",
              element: <TshirtsReports />,
            },
            {
              path: "/reports/fund-raising-reports",
              element: <FundRaisingReports />,
            },
            {
              path: "/reports/add-on-reports",
              element: <AddOnReports />,
            },
          ],
        },
        {
          path: "payment",
          element: <Payment />,
        },
        {
          path: "*",
          element: <Navigate to="/home" />,
        },
      ],
    },
  ]);
  return allPrivateRoutes;
};

export default PrivateRoutes;
